import { CODE_API, ElectronAPI } from '../baseURL'
import axios from '../config'

export default {
  //获取桌面端
  getDesktopUrl() {
    return axios.get(`${ElectronAPI}?appName=%E5%85%83%E4%B9%A0%20Setup`)
  },

  //获取桌面端
  downloadTextbook(data) {
    return axios.post(`http://localhost:23787/textbook/donwload`, data)
  },
}