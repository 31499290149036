import { PAYORDER_API, PAYORDER_API_1, PAYORDER_API_2 } from '../baseURL';
import axios from '../config';

const apiUrls = [PAYORDER_API, PAYORDER_API_1, PAYORDER_API_2];

async function fetchWithRetry(urls, endpoint, method = 'get', data = null) {
  for (const baseUrl of urls) {
    try {
      const url = `${baseUrl}${endpoint}`;
      if (method === 'get') {
        return await axios.get(url);
      } else if (method === 'post') {
        return await axios.post(url, data);
      } else if (method === 'put') {
        return await axios.put(url, data);
      }
    } catch (error) {
      console.error(`Failed to fetch from ${baseUrl}:`, error);
    }
  }
  throw new Error('All API requests failed');
}

export default {
  /////////////////////////订单
  //获取订单列表
  getOrderList(fk_userGuid) {
    return fetchWithRetry(apiUrls, `/payOrder/list?fk_userGuid=${fk_userGuid}`);
  },
  //下订单
  storeBookPayOrder(data) {
    return fetchWithRetry(apiUrls, '/payOrder/storeBookPayOrder', 'post', data);
  },
  //查订单
  findPayOrder(data) {
    const { pk_payorderGuid = '', fk_publishing_id = '', system_id = '', fk_userGuid = '' } = data;
    return fetchWithRetry(apiUrls, `/payOrder/payOrder?pk_payorderGuid=${pk_payorderGuid}&fk_publishing_id=${fk_publishing_id}&system_id=${system_id}&fk_userGuid=${fk_userGuid}`);
  },

  //////////////////////////优惠券
  //获取优惠券列表
  getCouponList(data) {
    const { fk_userGuid = '', status = '' } = data;
    return fetchWithRetry(apiUrls, `/coupon/list?fk_userGuid=${fk_userGuid}&status=${status}`);
  },
  //获取优惠券
  getCoupon(pk_couponGuid) {
    return fetchWithRetry(apiUrls, `/coupon/coupon?pk_couponGuid=${pk_couponGuid}`);
  },
  //修改优惠券信息
  updateCoupon(data) {
    return fetchWithRetry(apiUrls, '/coupon/coupon', 'put', data);
  },
  //批量生成
  batchCreate(data) {
    return fetchWithRetry(apiUrls, '/coupon/batchCreate', 'post', data);
  },
  //验证激活码
  verifyCode(data) {
    return fetchWithRetry(apiUrls, '/coupon/verifyCode', 'post', data);
  },
  //////////////////////////vip
  //查询信息
  getStudentVip(fk_user_id) {
    return fetchWithRetry(apiUrls, `/studentVip/studentVip?fk_user_id=${fk_user_id}`);
  },

  ///////////////////////////支付
  //提交订单
  submitOrder(data) {
    return fetchWithRetry(apiUrls, '/allinpay/submitorder', 'post', data);
  },
};