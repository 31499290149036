import { ACCOUNT_API, CLIENT_ACCOUNT, GROUPSERVE } from '../baseURL'
import axios from '../config'

export default {
  //发送短信验证码
  sendSms(phone) {
    return axios.post(`${ACCOUNT_API}/sms/sendDetail`, { Phone: phone });
  },
  //检查验证码是否正确
  checkcode(codeGuid, code) {
    return axios.get(`${ACCOUNT_API}/sms/checkcode?PK_CodeGuid=${codeGuid}&Code=${code}`);
  },
  //注册
  register(payload) {
    return axios.post(`${CLIENT_ACCOUNT}/user/register`, payload)
  },

  //获取账号
  getClientByUser(name) {
    return axios.get(`${CLIENT_ACCOUNT}/user/search?Name=${name}`);
  },
  //获取密保问题
  getProblemList() {
    return axios.get(`${CLIENT_ACCOUNT}/user/problem`);
  },
  //获取我的密保答案
  getMyProblem(userGuid) {
    return axios.get(`${CLIENT_ACCOUNT}/user/info/problem?userGuid=${userGuid}`);
  },
  //修改密保
  editSecurity(data) {
    return axios.put(`${CLIENT_ACCOUNT}/user/putSecurity`, data);
  },

  //检查密码是否正确
  checkPassword(userGuid, password) {
    return axios.get(`${CLIENT_ACCOUNT}/user/info/pass?userGuid=${userGuid}&password=${password}`);
  },
  //修改密码
  putPassword(PK_UserGuid, Password) {
    return axios.put(`${CLIENT_ACCOUNT}/user/putPassWord`, { PK_UserGuid, Password });
  },
  //重置密码
  setNewPassword(payload) {
    return axios.put(`${CLIENT_ACCOUNT}/user/putPassWord`, payload)
  },
  //获取分组方案
  getGroupScheme(id) {
    return axios.get(`${GROUPSERVE}/groupScheme?FK_BookClassGuid=${id}`)
  },
  //获取学生列表
  bookclassStudent(id) {
    return axios.get(`${GROUPSERVE}/bookclass/student/${id}`)
  },
  //获取学生列表
  userlist(data) {
    return axios.post(`${CLIENT_ACCOUNT}/user/userlist`, data)
  },
  //获取分组方案
  getOneGroupScheme(id) {
    return axios.get(`${GROUPSERVE}/groupScheme/${id}`)
  },
}