<template>
  <div class="mycourse fadeIn">
    <div class="title-text">我的下载</div>
    <div class="course-box">
      <div v-if="!storeBooks.length" class="course-item-box-null">
        暂无已下载教材
      </div>
      <div class="course-item-box" v-for="book of storeBooks" @click="chooseCourse(book)">
        <div class="course-item" :style="{ backgroundImage: 'url(' + book.cover + ')' }">
          <div class="course-info">
            <a-tooltip>
              <template #title>{{ book.name }}</template>
              <div class="course-title ellipsis2">{{ book.name }}</div>
            </a-tooltip>
            <!-- <div class="course-subTitle ellipsis" v-if="book.author_name">
              主编：{{ book.author_name }}
            </div> -->
            <!-- <div class="course-subTitle ellipsis" v-if="book.publisher">
              {{ book.publisher }}
            </div> -->
          </div>
        </div>
        <div class="course-bottom">
          <div class="course-unti">
            <svg class="svg-icon">
              <use xlink:href="#icon-book" />
            </svg>
            <span>{{ book.unitCount || 0 }}个章节</span>
          </div>
          <div class="course-status" :class="{ finished: book.isFinished }">
            <div v-if="book.isFinished">
              <svg class="svg-icon">
                <use xlink:href="#icon-duihao1" />
              </svg>
              <span>已完成</span>
            </div>
            <div v-else>
              <svg class="svg-icon">
                <use xlink:href="#icon-time" />
              </svg>
              <span>进行中</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { PASSPORT_CLIENT_SERVER, ONLINEURL, LESSONPLAY_TOKEN } from "@/config/url";
import foot from "@/component/public/foot/foot.vue";
import navroot from "@/component/public/nav/nav.vue";
import moment from "moment";
import { cloneDeep } from "lodash";
export default {
  components: {
    foot,
    navroot,
  },
  data() {
    return {
      storeBooks: [],
      newOpenBook: null,

      empty_image: "https://img.lessonplan.cn/IMG/yuanxi/images/empty.png",
      keyword: "",

      userInfo: null,
      userGuid: "",

      finishStatus: null,

      loading: true,
      vipPastdue: false,
      updateDgUser: false,
      phoneMode: false,
      config: null,
    };
  },
  computed: {

  },
  mounted() {
    if(this.$store.state.isElectron) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("getTextbookList");
      ipcRenderer.once("getTextbookList", (event, data) => {
        console.log(data)
        this.storeBooks = data
      });
    }
  },
  methods: {
    //选择教材
    chooseCourse(item) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("openTextbook", item.outline.PK_OutlineGuid);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./mydownload.scss";
</style>
